define("agenda/pods/authenticated/my-calendar/show-appointment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedMyCalendarShowAppointmentRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('authenticated.my-calendar').refetchEvents();
    }
  }
  _exports.default = AuthenticatedMyCalendarShowAppointmentRoute;
});