define("agenda/pods/authenticated/personal-planning/history/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3csSDTcZ",
    "block": "{\"symbols\":[\"@appointment\",\"@activeTab\"],\"statements\":[[10,\"tr\"],[15,0,[32,0,[\"quadrantClass\"]]],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"pl-3\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"my-1\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"my-1\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Não informado\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"start\"]],[30,[36,2],[[32,1,[\"start\"]],\"DD/MM/YYYY HH:mm\"],null],\"Não informado\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"end\"]],[30,[36,2],[[32,1,[\"end\"]],\"DD/MM/YYYY HH:mm\"],null],\"Não informado\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,0,[\"quadrantTitle\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,3],[[32,2],\"all\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[14,0,\"pr-3\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1,[\"archieved\"]],\"Arquivada\",\"Atual\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[32,2],\"archived\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm\"],[24,\"title\",\"Desarquivar tarefa\"],[4,[38,0],[\"click\",[32,0,[\"unarchive\"]]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fas fa-undo\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"moment-format\",\"eq\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/personal-planning/history/item/template.hbs"
    }
  });
  _exports.default = _default;
});