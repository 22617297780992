define("agenda/pods/authenticated/my-calendar/create/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedMyCalendarCreateRoute = (_dec = Ember.inject.service, (_class = class AuthenticatedMyCalendarCreateRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
    }
    model(params, transition) {
      let queryParams = transition.to.queryParams;
      return {
        appointment: this.store.createRecord('appointment', {
          active: true,
          createdBy: this.permission.user,
          user: this.permission.user,
          personalPlanning: queryParams.personalPlanning ? true : undefined
        }),
        queryParams
      };
    }
    afterModel(_ref, transition) {
      let {
        appointment
      } = _ref;
      let {
        date,
        description,
        title,
        start,
        end,
        room
      } = transition.to.queryParams;
      appointment.title = title ? title : '';
      appointment.description = description ? description : '';
      appointment.room = room ? this.store.peekRecord('room', room) : null;
      if (date) {
        const now = (0, _moment.default)().toDate();
        date = (0, _moment.default)(date);
        appointment.start = date.set({
          hour: now.getHours(),
          minute: now.getMinutes(),
          second: now.getSeconds(),
          millisecond: now.getMilliseconds()
        });
        appointment.start = appointment.start.toDate();
        appointment.end = (0, _moment.default)(date).add(1, 'hour').toDate();
      } else {
        date = (0, _moment.default)().toDate();
        appointment.start = date;
        appointment.end = (0, _moment.default)(date).add(1, 'hour').toDate();
      }
      appointment.start = start ? (0, _moment.default)(start).toDate() : appointment.start;
      appointment.end = end ? (0, _moment.default)(end).toDate() : appointment.end;
    }
    deactivate() {
      let {
        redirectTo
      } = this.controller.model.queryParams;
      this.controller.model.appointment.rollbackAttributes();
      this.controllerFor('authenticated.my-calendar').refetchEvents();
      if (redirectTo) {
        this.transitionTo(redirectTo);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthenticatedMyCalendarCreateRoute;
});