define("agenda/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fg9/goeK",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row align-items-center col-12 d-flex flex-column justify-content-center mt-5 pt-5\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-meh fa-5x text-primary mb-3\"],[12],[13],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"mb-5\"],[12],[1,[30,[36,0],[[32,0,[\"detail\"]],\"Página não encontrada\"],null]],[13],[2,\"\\n\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"authenticated.my-calendar\"]],[[\"default\"],[{\"statements\":[[2,\"Ir para página inicial\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "meta": {
      "moduleName": "agenda/pods/error/template.hbs"
    }
  });
  _exports.default = _default;
});