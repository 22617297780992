define("agenda/pods/authenticated/car-reservation/requests/form-show/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormShowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('args', 'args.reservation.start'), _dec10 = Ember.computed('args', 'args.reservation.predictedEnd'), _dec11 = Ember.computed('args.reservation.driver.isFulfilled'), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class FormShowComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "mileage", _descriptor6, this);
      _initializerDefineProperty(this, "devolutionDate", _descriptor7, this);
      _initializerDefineProperty(this, "isDevolutionModalActive", _descriptor8, this);
    }
    get start() {
      const {
        reservation
      } = this.args;
      return (0, _moment.default)(reservation.start).unix();
    }
    get predictedEnd() {
      const {
        reservation
      } = this.args;
      return (0, _moment.default)(reservation.predictedEnd).unix();
    }
    get isCnhExpired() {
      const {
        reservation
      } = this.args;
      const cnhExpirationDate = reservation.cnhExpirationDate;
      if (!cnhExpirationDate) return null;
      const isExpired = (0, _moment.default)(cnhExpirationDate).isSameOrBefore((0, _moment.default)());
      return isExpired;
    }
    *approve() {
      try {
        const cnhIsExpired = this.isCnhExpired === true;
        const cnhIsNotInformed = this.isCnhExpired === null || this.isCnhExpired === undefined;
        const {
          cars,
          reservation,
          transitionTo
        } = this.args;
        let whoApprove = 'manager';

        // Somente admin(responsável pelo carro) aprova de fato a solicitação
        if (transitionTo === 'admin') {
          const availableForSwap = cars.availableForSwap ? cars.availableForSwap.mapBy('id') : [];
          whoApprove = 'admin';
          if (!availableForSwap.includes(reservation.car.get('id'))) {
            return this.swal.warning('O veículo selecionado está indisponível para esta data.');
          }
        }
        if (cnhIsExpired) {
          const {
            value
          } = yield this.swal.confirm('CNH do motorista vencida, tem certeza que deseja aprovar?', {
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          if (!value) {
            return;
          }
        }
        if (cnhIsNotInformed) {
          const {
            value
          } = yield this.swal.confirm('CNH do motorista não foi informada, tem certeza que deseja aprovar?', {
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          if (!value) {
            return;
          }
        }
        let {
          value
        } = yield this.swal.confirm('Deseja mesmo aprovar esta solicitação?', {
          input: 'textarea',
          inputPlaceholder: 'Deixe uma mensagem (opcional)'
        });

        // O intuito aqui é verificar apenas se ele é `undefined`, se a string for vazia, deve continuar a execução.
        if (value === undefined) {
          return;
        }
        const carReservation = yield this.fetch.put(`carReservations/${whoApprove}/${reservation.get('id')}/approve`, {
          data: {
            car: reservation.car.get('id'),
            message: value
          }
        });
        this.store.pushPayload(carReservation);
        this.swal.success('Reserva de carro aprovada!');
        this.onCloseModal();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *disapprove() {
      const {
        reservation,
        transitionTo
      } = this.args;
      const whoApprove = transitionTo === 'admin' ? 'admin' : 'manager';
      try {
        const {
          value
        } = yield this.swal.confirm('Deseja mesmo reprovar esta solicitação?', {
          input: 'textarea',
          inputPlaceholder: 'Deixe uma mensagem (obrigatório)',
          inputValidator: value => {
            return !value && 'Você precisar deixar uma mensagem.';
          }
        });
        if (!value) {
          return;
        }
        const carReservation = yield this.fetch.put(`carReservations/${whoApprove}/${reservation.get('id')}/disapprove`, {
          data: {
            message: value
          }
        });
        this.store.pushPayload(carReservation);
        this.swal.success('Pedido de reserva de carro reprovada!');
        this.onCloseModal();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *complete() {
      const {
        reservation,
        transitionTo
      } = this.args;
      const whoApprove = transitionTo === 'admin' ? 'admin' : 'manager';
      if (this.mileage.match(/[^0-9]+/g)) {
        this.swal.warning('A quilometragem deve ser um número.');
        return;
      }
      if (!this.mileage) {
        this.swal.warning('Insira a quilometragem final.');
        return;
      }
      if (!this.devolutionDate) {
        this.swal.warning('Insira a data de retorno.');
        return;
      }
      if (reservation.start && (0, _moment.default)(reservation.start) > (0, _moment.default)(this.devolutionDate)) {
        this.swal.warning('A data de retorno não pode ser anterior a data da saída.');
        return;
      }
      try {
        const carReservation = yield this.fetch.put(`carReservations/${whoApprove}/${reservation.get('id')}/complete`, {
          data: {
            finalKm: Number(this.mileage),
            end: this.devolutionDate
          }
        });
        this.store.pushPayload(carReservation);
        this.swal.success('Reserva de carro finalizada com sucesso?');
        this.onCloseModal();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    showDevolutionModal() {
      this.mileage = '';
      this.devolutionDate = new Date();
      this.isDevolutionModalActive = true;
    }
    onCloseModal() {
      let {
        transitionTo
      } = this.args;
      transitionTo = transitionTo ? transitionTo : 'index';
      this.router.transitionTo(`authenticated.car-reservation.requests.${transitionTo}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mileage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "devolutionDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isDevolutionModalActive", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "predictedEnd", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "predictedEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCnhExpired", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isCnhExpired"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disapprove", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "disapprove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "complete", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "complete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDevolutionModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showDevolutionModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseModal"), _class.prototype)), _class));
  _exports.default = FormShowComponent;
});