define("agenda/pods/authenticated/car-reservation/requests/admin/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8SsDXSub",
    "block": "{\"symbols\":[],\"statements\":[[8,\"authenticated/car-reservation/requests/form-show\",[],[[\"@transitionTo\",\"@reservation\",\"@cars\"],[\"admin\",[32,0,[\"model\",\"reservation\"]],[32,0,[\"model\",\"cars\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/car-reservation/requests/admin/show/template.hbs"
    }
  });
  _exports.default = _default;
});