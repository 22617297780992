define("agenda/pods/authenticated/my-calendar/edit-recurrent/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedMyCalendarEditRecurrentRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('authenticated.my-calendar').refetchEvents();
    }
  }
  _exports.default = AuthenticatedMyCalendarEditRecurrentRoute;
});