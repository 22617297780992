define("agenda/models/appointment", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'Informe um título'
    })
  });
  let AppointmentModel = (_dec = (0, _model.attr)('boolean'), _dec2 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec3 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('raw'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string', {
    defaultValue: 'none'
  }), _dec12 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec19 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec20 = (0, _model.attr)('date'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec23 = (0, _model.belongsTo)('appointment'), _dec24 = (0, _model.belongsTo)('company'), _dec25 = (0, _model.belongsTo)('user', {
    inverse: null
  }), _dec26 = (0, _model.belongsTo)('recurrentAppointment', {
    inverse: null
  }), _dec27 = (0, _model.belongsTo)('room'), _dec28 = (0, _model.belongsTo)('user', {
    inverse: 'appointments'
  }), _dec29 = (0, _model.belongsTo)('user-message'), _dec30 = (0, _model.hasMany)('alert', {
    inverse: 'appointment'
  }), _dec31 = (0, _model.hasMany)('user', {
    inverse: 'confirmedAppointments'
  }), _dec32 = (0, _model.hasMany)('user', {
    inverse: 'invitedAppointments'
  }), _dec33 = (0, _model.hasMany)('appointment-resource', {
    inverse: 'appointment'
  }), (_class = class AppointmentModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "archieved", _descriptor, this);
      _initializerDefineProperty(this, "absence", _descriptor2, this);
      _initializerDefineProperty(this, "canceled", _descriptor3, this);
      _initializerDefineProperty(this, "createdAt", _descriptor4, this);
      _initializerDefineProperty(this, "description", _descriptor5, this);
      _initializerDefineProperty(this, "end", _descriptor6, this);
      _initializerDefineProperty(this, "externalUsers", _descriptor7, this);
      _initializerDefineProperty(this, "googleCalendar", _descriptor8, this);
      _initializerDefineProperty(this, "googleCalendarId", _descriptor9, this);
      _initializerDefineProperty(this, "googleCalendarLink", _descriptor10, this);
      _initializerDefineProperty(this, "importanceQuadrant", _descriptor11, this);
      _initializerDefineProperty(this, "isRecurrent", _descriptor12, this);
      _initializerDefineProperty(this, "location", _descriptor13, this);
      _initializerDefineProperty(this, "notifyAll", _descriptor14, this);
      _initializerDefineProperty(this, "outlookCalendar", _descriptor15, this);
      _initializerDefineProperty(this, "outlookCalendarId", _descriptor16, this);
      _initializerDefineProperty(this, "outlookCalendarLink", _descriptor17, this);
      _initializerDefineProperty(this, "personalPlanning", _descriptor18, this);
      _initializerDefineProperty(this, "public", _descriptor19, this);
      _initializerDefineProperty(this, "start", _descriptor20, this);
      _initializerDefineProperty(this, "title", _descriptor21, this);
      _initializerDefineProperty(this, "terminated", _descriptor22, this);
      _initializerDefineProperty(this, "appointmentSource", _descriptor23, this);
      _initializerDefineProperty(this, "company", _descriptor24, this);
      _initializerDefineProperty(this, "createdBy", _descriptor25, this);
      _initializerDefineProperty(this, "recurrentAppointment", _descriptor26, this);
      _initializerDefineProperty(this, "room", _descriptor27, this);
      _initializerDefineProperty(this, "user", _descriptor28, this);
      _initializerDefineProperty(this, "userMessage", _descriptor29, this);
      _initializerDefineProperty(this, "alerts", _descriptor30, this);
      _initializerDefineProperty(this, "confirmedUsers", _descriptor31, this);
      _initializerDefineProperty(this, "invitedUsers", _descriptor32, this);
      _initializerDefineProperty(this, "resources", _descriptor33, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "archieved", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "absence", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canceled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "end", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "externalUsers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "googleCalendar", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "googleCalendarId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "googleCalendarLink", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "importanceQuadrant", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isRecurrent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "notifyAll", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "outlookCalendar", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "outlookCalendarId", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "outlookCalendarLink", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "personalPlanning", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "public", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "start", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "terminated", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "appointmentSource", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "recurrentAppointment", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "room", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "userMessage", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "alerts", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "confirmedUsers", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "invitedUsers", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "resources", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppointmentModel;
});