define("agenda/pods/components/skeleton/list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8l9G6f+U",
    "block": "{\"symbols\":[\"Skeleton\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[],[[\"@count\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-0 w-75\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "agenda/pods/components/skeleton/list-item/template.hbs"
    }
  });
  _exports.default = _default;
});