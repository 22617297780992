define("agenda/pods/authenticated/my-calendar/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedMyCalendarEditRoute extends Ember.Route {
    deactivate() {
      let {
        redirectTo
      } = this.controller;
      this.controllerFor('authenticated.my-calendar').refetchEvents();
      if (redirectTo) {
        this.transitionTo(redirectTo);
      }
    }
  }
  _exports.default = AuthenticatedMyCalendarEditRoute;
});