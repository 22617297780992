define("agenda/pods/authenticated/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedInviteRoute extends Ember.Route {
    model(params, transition) {
      let type = transition.to.queryParams.type;
      return type;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.filters = {
        status: model
      };
    }
  }
  _exports.default = AuthenticatedInviteRoute;
});