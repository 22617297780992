define("agenda/pods/authenticated/car-reservation/requests/manager/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedCarReservationRequestsManagerShowRoute extends Ember.Route {
    model(_ref) {
      let {
        car_reservation_id
      } = _ref;
      return this.store.loadRecord('car-reservation', `/carReservations/manager/${car_reservation_id}`);
    }
    deactivate() {
      this.controllerFor('authenticated.car-reservation.requests.manager').clearFilters();
    }
  }
  _exports.default = AuthenticatedCarReservationRequestsManagerShowRoute;
});