define("agenda/pods/authenticated/my-calendar/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g0Xxsm1N",
    "block": "{\"symbols\":[],\"statements\":[[8,\"appointment-form\",[],[[\"@appointment\",\"@onCloneModel\"],[[32,0,[\"appointment\"]],[30,[36,0],[[32,0,[\"onCloneModel\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/my-calendar/create/template.hbs"
    }
  });
  _exports.default = _default;
});