define("agenda/pods/authenticated/reports/car-reservation/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedReportsCarReservationController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class AuthenticatedReportsCarReservationController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "car", _descriptor2, this);
      _initializerDefineProperty(this, "department", _descriptor3, this);
      _initializerDefineProperty(this, "driver", _descriptor4, this);
      _initializerDefineProperty(this, "destination", _descriptor5, this);
      _initializerDefineProperty(this, "finalDate", _descriptor6, this);
      _initializerDefineProperty(this, "startDate", _descriptor7, this);
    }
    buildFilters() {
      const filter = {};
      const {
        department,
        car,
        driver,
        destination
      } = this;
      if (this.startDate) {
        filter.startDate = (0, _moment.default)(this.startDate).unix();
      }
      if (this.finalDate) {
        filter.finalDate = (0, _moment.default)(this.finalDate).endOf('day').unix();
      }
      if (department) {
        filter.department = department.id;
      }
      if (car) {
        filter.car = car.id;
      }
      if (driver) {
        filter.driver = driver.id;
      }
      if (destination) {
        filter.destination = destination.id;
      }
      return filter;
    }
    clearFilters() {
      this.startDate = null;
      this.finalDate = null;
      this.department = null;
      this.car = null;
      this.driver = null;
      this.destination = null;
    }
    validateFilters() {
      if (!this.startDate || !this.finalDate) {
        this.swal.warning('A data inicial e final devem ser informadas');
        return false;
      } else {
        let startDate = (0, _moment.default)(this.startDate).unix();
        let finalDate = (0, _moment.default)(this.finalDate).endOf('day').unix();
        if (finalDate < startDate) {
          this.swal.warning('A data final não pode ser anterior à data inicial');
          return false;
        }
      }
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "car", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "department", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "driver", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "destination", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "finalDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateFilters", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "validateFilters"), _class.prototype)), _class));
  _exports.default = AuthenticatedReportsCarReservationController;
});