define("agenda/models/user", ["exports", "@ember-data/model", "agenda/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _dec64, _dec65, _dec66, _dec67, _dec68, _dec69, _dec70, _dec71, _dec72, _dec73, _dec74, _dec75, _dec76, _dec77, _dec78, _dec79, _dec80, _dec81, _dec82, _dec83, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60, _descriptor61, _descriptor62, _descriptor63, _descriptor64, _descriptor65, _descriptor66, _descriptor67, _descriptor68, _descriptor69, _descriptor70, _descriptor71, _descriptor72, _descriptor73, _descriptor74, _descriptor75, _descriptor76, _descriptor77, _descriptor78, _descriptor79, _descriptor80, _descriptor81, _descriptor82, _descriptor83;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserModel = (_dec = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec2 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec11 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string', {
    defaultValue: ''
  }), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec32 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec33 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('string'), _dec39 = (0, _model.attr)('string'), _dec40 = (0, _model.attr)('string'), _dec41 = (0, _model.attr)('string'), _dec42 = (0, _model.attr)('string'), _dec43 = (0, _model.attr)('number'), _dec44 = (0, _model.attr)('string'), _dec45 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec46 = (0, _model.attr)('string'), _dec47 = (0, _model.attr)('string'), _dec48 = (0, _model.attr)('string'), _dec49 = (0, _model.attr)('string'), _dec50 = (0, _model.attr)('string'), _dec51 = (0, _model.attr)('string'), _dec52 = (0, _model.attr)('string'), _dec53 = (0, _model.attr)('string'), _dec54 = (0, _model.attr)('string'), _dec55 = (0, _model.attr)('string'), _dec56 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec57 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec58 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec59 = (0, _model.attr)('string'), _dec60 = (0, _model.attr)('string'), _dec61 = (0, _model.attr)('number'), _dec62 = (0, _model.attr)('string'), _dec63 = (0, _model.attr)('string'), _dec64 = (0, _model.belongsTo)('accessProfile'), _dec65 = (0, _model.belongsTo)('user', {
    inverse: null
  }), _dec66 = (0, _model.belongsTo)('company'), _dec67 = (0, _model.belongsTo)('department'), _dec68 = (0, _model.belongsTo)('job-function'), _dec69 = (0, _model.belongsTo)('job-role'), _dec70 = (0, _model.hasMany)('appointment'), _dec71 = (0, _model.hasMany)('branch', {
    inverse: 'users'
  }), _dec72 = (0, _model.hasMany)('appointment', {
    inverse: 'confirmedUsers'
  }), _dec73 = (0, _model.hasMany)('car'), _dec74 = (0, _model.hasMany)('recurrent-appointment', {
    inverse: 'confirmedUsers'
  }), _dec75 = (0, _model.hasMany)('department', {
    inverse: 'usersResponsibleForSolicitation'
  }), _dec76 = (0, _model.hasMany)('appointment', {
    inverse: 'invitedUsers'
  }), _dec77 = (0, _model.hasMany)('recurrent-appointment', {
    inverse: 'invitedUsers'
  }), _dec78 = (0, _model.hasMany)('user', {
    inverse: null
  }), _dec79 = (0, _model.hasMany)('meeting-minute', {
    inverse: 'usersAdmin'
  }), _dec80 = (0, _model.hasMany)('meeting-minute', {
    inverse: 'usersParticipant'
  }), _dec81 = (0, _model.hasMany)('user-group', {
    inverse: 'admins'
  }), _dec82 = (0, _model.hasMany)('user', {
    inverse: null
  }), _dec83 = (0, _model.hasMany)('user-group', {
    inverse: 'users'
  }), (_class = class UserModel extends _model.default.extend() {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "active", _descriptor, this);
      _initializerDefineProperty(this, "loginSuperUser", _descriptor2, this);
      _initializerDefineProperty(this, "address", _descriptor3, this);
      _initializerDefineProperty(this, "admin", _descriptor4, this);
      _initializerDefineProperty(this, "admissionDate", _descriptor5, this);
      _initializerDefineProperty(this, "avatarUrl", _descriptor6, this);
      _initializerDefineProperty(this, "away", _descriptor7, this);
      _initializerDefineProperty(this, "birthDate", _descriptor8, this);
      _initializerDefineProperty(this, "cnhExpirationDate", _descriptor9, this);
      _initializerDefineProperty(this, "brigade", _descriptor10, this);
      _initializerDefineProperty(this, "createdByNela", _descriptor11, this);
      _initializerDefineProperty(this, "cellphone", _descriptor12, this);
      _initializerDefineProperty(this, "complement", _descriptor13, this);
      _initializerDefineProperty(this, "cipeiro", _descriptor14, this);
      _initializerDefineProperty(this, "complement", _descriptor15, this);
      _initializerDefineProperty(this, "confirmPassword", _descriptor16, this);
      _initializerDefineProperty(this, "cpf", _descriptor17, this);
      _initializerDefineProperty(this, "currentPassword", _descriptor18, this);
      _initializerDefineProperty(this, "email", _descriptor19, this);
      _initializerDefineProperty(this, "emergencyContactName", _descriptor20, this);
      _initializerDefineProperty(this, "emergencyContactPhone", _descriptor21, this);
      _initializerDefineProperty(this, "externalLine", _descriptor22, this);
      _initializerDefineProperty(this, "facebookUrl", _descriptor23, this);
      _initializerDefineProperty(this, "favoriteAuthor", _descriptor24, this);
      _initializerDefineProperty(this, "favoriteDishes", _descriptor25, this);
      _initializerDefineProperty(this, "favoriteSport", _descriptor26, this);
      _initializerDefineProperty(this, "favoriteTeam", _descriptor27, this);
      _initializerDefineProperty(this, "firstName", _descriptor28, this);
      _initializerDefineProperty(this, "gender", _descriptor29, this);
      _initializerDefineProperty(this, "googleplusUrl", _descriptor30, this);
      _initializerDefineProperty(this, "groupsAdmin", _descriptor31, this);
      _initializerDefineProperty(this, "hasNoLeader", _descriptor32, this);
      _initializerDefineProperty(this, "hasNoGoal", _descriptor33, this);
      _initializerDefineProperty(this, "hobbies", _descriptor34, this);
      _initializerDefineProperty(this, "instagramUrl", _descriptor35, this);
      _initializerDefineProperty(this, "isLeader", _descriptor36, this);
      _initializerDefineProperty(this, "language", _descriptor37, this);
      _initializerDefineProperty(this, "lastName", _descriptor38, this);
      _initializerDefineProperty(this, "lastSeen", _descriptor39, this);
      _initializerDefineProperty(this, "linkedinUrl", _descriptor40, this);
      _initializerDefineProperty(this, "neighborhood", _descriptor41, this);
      _initializerDefineProperty(this, "newPassword", _descriptor42, this);
      _initializerDefineProperty(this, "number", _descriptor43, this);
      _initializerDefineProperty(this, "otherSport", _descriptor44, this);
      _initializerDefineProperty(this, "practicesSports", _descriptor45, this);
      _initializerDefineProperty(this, "professionalAddress", _descriptor46, this);
      _initializerDefineProperty(this, "professionalCellphone", _descriptor47, this);
      _initializerDefineProperty(this, "professionalCompany", _descriptor48, this);
      _initializerDefineProperty(this, "professionalComplement", _descriptor49, this);
      _initializerDefineProperty(this, "professionalNeighborhood", _descriptor50, this);
      _initializerDefineProperty(this, "professionalNumber", _descriptor51, this);
      _initializerDefineProperty(this, "professionalPhone", _descriptor52, this);
      _initializerDefineProperty(this, "professionalZipcode", _descriptor53, this);
      _initializerDefineProperty(this, "registration", _descriptor54, this);
      _initializerDefineProperty(this, "skype", _descriptor55, this);
      _initializerDefineProperty(this, "superUser", _descriptor56, this);
      _initializerDefineProperty(this, "googleOAuth", _descriptor57, this);
      _initializerDefineProperty(this, "outlookOAuth", _descriptor58, this);
      _initializerDefineProperty(this, "status", _descriptor59, this);
      _initializerDefineProperty(this, "twitterUrl", _descriptor60, this);
      _initializerDefineProperty(this, "typeEvaluation", _descriptor61, this);
      _initializerDefineProperty(this, "username", _descriptor62, this);
      _initializerDefineProperty(this, "zipcode", _descriptor63, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor64, this);
      _initializerDefineProperty(this, "appraisedBy", _descriptor65, this);
      _initializerDefineProperty(this, "company", _descriptor66, this);
      _initializerDefineProperty(this, "department", _descriptor67, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor68, this);
      _initializerDefineProperty(this, "jobRole", _descriptor69, this);
      _initializerDefineProperty(this, "appointments", _descriptor70, this);
      _initializerDefineProperty(this, "branches", _descriptor71, this);
      _initializerDefineProperty(this, "confirmedAppointments", _descriptor72, this);
      _initializerDefineProperty(this, "cars", _descriptor73, this);
      _initializerDefineProperty(this, "confirmedRecurrentAppointments", _descriptor74, this);
      _initializerDefineProperty(this, "departmentsResponsibleForSolicitation", _descriptor75, this);
      _initializerDefineProperty(this, "invitedAppointments", _descriptor76, this);
      _initializerDefineProperty(this, "invitedRecurrentAppointments", _descriptor77, this);
      _initializerDefineProperty(this, "leds", _descriptor78, this);
      _initializerDefineProperty(this, "meetingAdmin", _descriptor79, this);
      _initializerDefineProperty(this, "meetingParticipant", _descriptor80, this);
      _initializerDefineProperty(this, "userGroupsAdmin", _descriptor81, this);
      _initializerDefineProperty(this, "userAppraisers", _descriptor82, this);
      _initializerDefineProperty(this, "userGroups", _descriptor83, this);
    }
    get avatar() {
      if (!this.active) {
        return `${_environment.default.CDN}images/inactive-user.png`;
      }
      if (!this.avatarUrl) {
        return '/ui-nela/images/user-default.png';
      }
      return this.avatarUrl;
    }
    get fullName() {
      if (this.isLoading) {
        return '...';
      }
      if (!this.active) {
        return `(INATIVO) ${this.firstName} ${this.lastName}`;
      }
      return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loginSuperUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "admissionDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "avatarUrl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "away", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "birthDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "cnhExpirationDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "brigade", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "createdByNela", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "cellphone", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "complement", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "cipeiro", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "complement", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "confirmPassword", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "cpf", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "currentPassword", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "emergencyContactName", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "emergencyContactPhone", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "externalLine", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "facebookUrl", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "favoriteAuthor", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "favoriteDishes", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "favoriteSport", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "favoriteTeam", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "gender", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "googleplusUrl", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "groupsAdmin", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "hasNoLeader", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "hasNoGoal", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "hobbies", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "instagramUrl", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "isLeader", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "lastSeen", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "linkedinUrl", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "neighborhood", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "newPassword", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "number", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "otherSport", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "practicesSports", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "professionalAddress", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "professionalCellphone", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "professionalCompany", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "professionalComplement", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "professionalNeighborhood", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "professionalNumber", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "professionalPhone", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "professionalZipcode", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "registration", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "skype", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "superUser", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "googleOAuth", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = _applyDecoratedDescriptor(_class.prototype, "outlookOAuth", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = _applyDecoratedDescriptor(_class.prototype, "twitterUrl", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor61 = _applyDecoratedDescriptor(_class.prototype, "typeEvaluation", [_dec61], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor62 = _applyDecoratedDescriptor(_class.prototype, "username", [_dec62], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor63 = _applyDecoratedDescriptor(_class.prototype, "zipcode", [_dec63], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor64 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec64], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor65 = _applyDecoratedDescriptor(_class.prototype, "appraisedBy", [_dec65], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor66 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec66], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor67 = _applyDecoratedDescriptor(_class.prototype, "department", [_dec67], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor68 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec68], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor69 = _applyDecoratedDescriptor(_class.prototype, "jobRole", [_dec69], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor70 = _applyDecoratedDescriptor(_class.prototype, "appointments", [_dec70], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor71 = _applyDecoratedDescriptor(_class.prototype, "branches", [_dec71], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor72 = _applyDecoratedDescriptor(_class.prototype, "confirmedAppointments", [_dec72], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor73 = _applyDecoratedDescriptor(_class.prototype, "cars", [_dec73], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor74 = _applyDecoratedDescriptor(_class.prototype, "confirmedRecurrentAppointments", [_dec74], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor75 = _applyDecoratedDescriptor(_class.prototype, "departmentsResponsibleForSolicitation", [_dec75], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor76 = _applyDecoratedDescriptor(_class.prototype, "invitedAppointments", [_dec76], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor77 = _applyDecoratedDescriptor(_class.prototype, "invitedRecurrentAppointments", [_dec77], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor78 = _applyDecoratedDescriptor(_class.prototype, "leds", [_dec78], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor79 = _applyDecoratedDescriptor(_class.prototype, "meetingAdmin", [_dec79], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor80 = _applyDecoratedDescriptor(_class.prototype, "meetingParticipant", [_dec80], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor81 = _applyDecoratedDescriptor(_class.prototype, "userGroupsAdmin", [_dec81], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor82 = _applyDecoratedDescriptor(_class.prototype, "userAppraisers", [_dec82], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor83 = _applyDecoratedDescriptor(_class.prototype, "userGroups", [_dec83], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserModel;
});