define("agenda/pods/authenticated/personal-planning/index/quadrant/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7NfUEzJu",
    "block": "{\"symbols\":[\"Card\",\"@appointment\"],\"statements\":[[8,\"draggable-object\",[],[[\"@content\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-card\",[[24,0,\"personal-planning-item mb-2 group\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[1,[32,2,[\"title\"]]],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"hoverable-item-buttons group-hover:opacity-10\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-sm btn-link py-0 pr-0\"],[14,\"title\",\"Arquivar tarefa\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"archive\"]]],null]],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-archive\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@title\",\"@class\"],[\"authenticated.personal-planning.index.edit\",[32,2],\"Editar tarefa\",\"btn btn-sm btn-link py-0 pr-0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-pencil\"],[12],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[10,\"button\"],[14,0,\"btn btn-sm btn-link py-0 pr-2\"],[14,\"title\",\"Excluir tarefa\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"remove\"]]],null]],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-times\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/personal-planning/index/quadrant/item/template.hbs"
    }
  });
  _exports.default = _default;
});