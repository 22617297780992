define("agenda/pods/application/notification/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+t82ejk/",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[10,\"li\"],[15,0,[31,[\"list-group-item px-0 text-nowrap \",[32,0,[\"unreadClass\"]]]]],[12],[2,\"\\n\"],[2,\"  \"],[8,\"link-to\",[[24,0,\"d-flex\"],[16,\"onclick\",[30,[36,0],[[32,0,[\"setAsRead\"]]],null]]],[[\"@route\",\"@activeClass\"],[\"authenticated.my-calendar\",\"text-body\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1,[\"read\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"far fa-dot-circle small text-primary position-absolute r-2 t-2\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-fill ml-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mb-0 item-group-size\"],[12],[1,[30,[36,2],[[32,1,[\"message\"]]],null]],[13],[2,\"\\n\\n      \"],[10,\"small\"],[15,\"title\",[30,[36,3],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,4],[[32,1,[\"createdAt\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"unless\",\"html\",\"moment-format\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "agenda/pods/application/notification/item/template.hbs"
    }
  });
  _exports.default = _default;
});