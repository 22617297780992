define("agenda/pods/application/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AhSRZ/Mn",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[11,\"li\"],[24,0,\"navbar-dropdown\"],[4,[38,2],[[32,0,[\"initNotifications\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"notifications\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row notification-dropdown\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-left mb-0 notification-dropdown-item\"],[12],[2,\"Notificações\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-right mb-0\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-link text-body notification-dropdown-item-2\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"readAllNotifications\"]]],null]],[12],[2,\"\\n            Marcar todas como lidas\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"list-group list-group-flush list-unstyled px-3 list-group-size\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"notifications\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"application/notification/item\",[],[[\"@notification\",\"@reloadNotifications\"],[[32,1],[30,[36,0],[[32,0,[\"initNotifications\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"text-center py-3\"],[12],[2,\"Não há novas notificações\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"perform\",\"did-insert\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "agenda/pods/application/notification/template.hbs"
    }
  });
  _exports.default = _default;
});