define("agenda/pods/authenticated/room-scheduling/create/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedRoomSchedulingCreateRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class AuthenticatedRoomSchedulingCreateRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    beforeModel() {
      const {
        accessProfile: {
          allowReserveMeetingRoom
        },
        admin
      } = this.permission;
      const routeIsForbidden = !allowReserveMeetingRoom && !admin;
      console.log({
        allowReserveMeetingRoom,
        admin
      });
      if (routeIsForbidden) {
        return this.router.transitionTo('forbidden');
      }
    }
    model(params, transition) {
      let queryParams = transition.to.queryParams;
      return {
        appointment: this.store.createRecord('appointment', {
          active: true,
          createdBy: this.permission.user,
          user: this.permission.user
        }),
        queryParams
      };
    }
    afterModel(_ref, transition) {
      let {
        appointment
      } = _ref;
      let {
        date
      } = transition.to.queryParams;
      if (date) {
        date = (0, _moment.default)(date).set({
          'hour': (0, _moment.default)().get('hour'),
          'minute': (0, _moment.default)().get('minute')
        }).toDate();
        appointment.start = date;
        appointment.end = (0, _moment.default)(date).add(1, 'hour').toDate();
      } else {
        date = (0, _moment.default)().toDate();
        appointment.start = date;
        appointment.end = (0, _moment.default)(date).add(1, 'hour').toDate();
      }
    }
    deactivate() {
      this.controllerFor('authenticated.room-scheduling').refetchEvents();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthenticatedRoomSchedulingCreateRoute;
});