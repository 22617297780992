define("agenda/pods/authenticated/personal-planning/history/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ItemComponent = (_dec = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "quadrants", Ember.A([{
        name: 'q1',
        title: 'Importante e urgente',
        subtitle: 'Agende agora!',
        class: 'table-danger'
      }, {
        name: 'q2',
        title: 'Importante e não urgente',
        subtitle: 'Agende um tempo maior antes que se torne urgente.',
        class: 'table-info'
      }, {
        name: 'q3',
        title: 'Não importante e urgente',
        subtitle: 'Pense bem... Se não é importante, você tem mesmo que agendar?',
        class: 'table-warning'
      }, {
        name: 'q4',
        title: 'Não importante e não urgente',
        subtitle: 'Nem deveria haver espaço para isso na sua agenda.',
        class: 'table-success'
      }]));
    }
    get quadrantTitle() {
      const quadrant = this.quadrants.find(q => q.name === this.args.appointment.importanceQuadrant);
      return quadrant?.title || 'Nenhum';
    }
    get quadrantClass() {
      const quadrant = this.quadrants.find(q => q.name === this.args.appointment.importanceQuadrant);
      return quadrant?.class || '';
    }
    unarchive() {
      this.args.unarchive.perform(this.args.appointment);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "unarchive", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "unarchive"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});