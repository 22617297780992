define("agenda/pods/authenticated/car-reservation/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('args', 'args.carReservation.{predictedEnd,start}', 'searchCars'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "filteredCars", _descriptor6, this);
      _initializerDefineProperty(this, "cars", _descriptor7, this);
      this.searchCars.perform();
    }
    get carSelectDisabled() {
      const {
        carReservation
      } = this.args;
      let start = carReservation.start;
      let predictedEnd = carReservation.predictedEnd;
      return Ember.isEmpty(start) || Ember.isEmpty(predictedEnd);
    }
    *save() {
      try {
        let {
          carReservation
        } = this.args;
        let message = carReservation.isNew ? 'Reserva de carro cadastrada' : 'Reserva de carro atualizada';
        if (!carReservation.validations.isValid) {
          return this.swal.validation(carReservation.validations);
        }
        if (!carReservation.start) {
          return this.swal.warning('Informe uma data de saída');
        }
        if (!carReservation.predictedEnd) {
          return this.swal.warning('Informe uma data estimada de retorno');
        }
        yield carReservation.save();
        this.toast.success(message);
        this.router.transitionTo('authenticated.car-reservation.index');
      } catch (e) {
        console.log('...', e);
        return this.swal.catch(e);
      }
    }
    *searchCars() {
      this.cars = Ember.A([]);
      const {
        carReservation
      } = this.args;
      const start = (0, _moment.default)(carReservation.start).unix();
      const predictedEnd = (0, _moment.default)(carReservation.predictedEnd).unix();
      const data = {
        start,
        predictedEnd
      };
      try {
        const response = yield this.store.loadRecords('car', 'cars/available', {
          data
        });
        this.cars = response;
        this.filteredCars = this.cars;
      } catch (e) {
        Ember.debug(`Authenticated :: CarReservation :: controller :: searchCars :: ${e}`);
      }
    }
    afterSetDate() {
      const {
        carReservation
      } = this.args;
      carReservation.car = null;
      const start = carReservation.start;
      const end = carReservation.predictedEnd;
      if (start && end) {
        this.searchCars.perform(start, end);
      }
    }
    onChangeStartDate(_ref) {
      let {
        moment: date
      } = _ref;
      const {
        carReservation
      } = this.args;
      const start = (0, _moment.default)(date).isSame(carReservation.start);
      const end = carReservation.predictedEnd;
      if (!date || !start) {
        carReservation.start = (0, _moment.default)(date).toDate();
        if ((0, _moment.default)(end).isBefore(date)) {
          carReservation.predictedEnd = (0, _moment.default)(date).toDate();
        }
        this.afterSetDate();
      }
    }
    onChangeEndDate(_ref2) {
      let {
        moment: date
      } = _ref2;
      const {
        carReservation
      } = this.args;
      const end = (0, _moment.default)(date).isSame(carReservation.predictedEnd);
      const start = carReservation.start;
      if (!date || !end) {
        carReservation.predictedEnd = (0, _moment.default)(date).toDate();
        if ((0, _moment.default)(date).isBefore(start)) {
          carReservation.predictedEnd = (0, _moment.default)(start).toDate();
        }
        this.afterSetDate();
      }
    }
    searchCar(term) {
      this.filteredCars = this.cars.filter(car => {
        let carName = (car.fullDescription || '').toLowerCase();
        if (carName.indexOf(term.toLowerCase()) !== -1) {
          return true;
        }
      });
    }
    closeModal() {
      const transitionTo = this.args.transitionTo || 'index';
      this.args.carReservation.rollbackAttributes();
      this.router.transitionTo(`authenticated.car-reservation.${transitionTo}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filteredCars", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cars", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "carSelectDisabled", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "carSelectDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCars", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCars"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStartDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeEndDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCar", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "searchCar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FormComponent;
});