define("agenda/pods/components/recurrent-form/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RecurrentFormComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.equal('args.appointment.freq', 'YEARLY'), _dec6 = Ember.computed.equal('args.appointment.freq', 'DAILY'), _dec7 = Ember.computed.equal('args.appointment.daily', 'interval'), _dec8 = Ember.computed.equal('args.appointment.freq', 'MONTHLY'), _dec9 = Ember.computed.equal('args.appointment.final', 'until'), _dec10 = Ember.computed.equal('args.appointment.freq', 'WEEKLY'), _dec11 = Ember.computed('args.appointment.{freq,start,monthly,yearly}', 'args', 'bymonths', 'byweekdays'), _dec12 = Ember.computed('args.appointment.{freq,start}', 'args'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class RecurrentFormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "frequencySelected", _descriptor, this);
      _defineProperty(this, "frequencies", Ember.A([{
        label: 'Diariamente',
        value: 'DAILY'
      }, {
        label: 'Semanalmente',
        value: 'WEEKLY'
      }, {
        label: 'Mensalmente',
        value: 'MONTHLY'
      }, {
        label: 'Anualmente',
        value: 'YEARLY'
      }]));
      _initializerDefineProperty(this, "bymonths", _descriptor2, this);
      _initializerDefineProperty(this, "byweekdays", _descriptor3, this);
      _initializerDefineProperty(this, "daysOfWeek", _descriptor4, this);
      _initializerDefineProperty(this, "isAnnually", _descriptor5, this);
      _initializerDefineProperty(this, "isDaily", _descriptor6, this);
      _initializerDefineProperty(this, "isDailyInterval", _descriptor7, this);
      _initializerDefineProperty(this, "isMonthly", _descriptor8, this);
      _initializerDefineProperty(this, "isUntilFinal", _descriptor9, this);
      _initializerDefineProperty(this, "isWeekly", _descriptor10, this);
    }
    get appointmentWeekday() {
      const {
        appointment
      } = this.args;
      const day = (0, _moment.default)(appointment.start).date();
      const weekday = (0, _moment.default)(appointment.start).day();
      const month = (0, _moment.default)(appointment.start).month();
      let week = Math.ceil(day / 7);
      const nextMonth = (0, _moment.default)(appointment.start).add(1, 'M').startOf('month');
      const lastWeek = nextMonth.subtract(7, 'd');
      if ((0, _moment.default)(appointment.start).isSameOrAfter(lastWeek)) {
        week = 0;
      }
      let order = Ember.A(['na última', 'na primeira', 'na segunda', 'na terceira', 'na quarta']);
      if ([0, 6].includes(weekday)) {
        order = order.map(str => str.replace(/a/g, 'o'));
      }
      if (appointment.monthly === 'bysetpos') {
        this.setPosition(week, this.byweekdays[weekday].value);
      } else if (appointment.monthly === 'bymonthday') {
        this.setMonthDay(day);
      } else if (appointment.yearly === 'bysetpos') {
        this.setPosition(week, this.byweekdays[weekday].value, this.bymonths[month].value);
      } else if (appointment.yearly === 'bymonthday') {
        this.setMonthDay(day, this.bymonths[month].value);
      }
      let occurrence = `${order[week]} ${this.byweekdays[weekday].label} `;
      if (appointment.freq === 'YEARLY') {
        occurrence += `de ${this.bymonths[month].label}`;
      } else {
        occurrence += 'do mês';
      }
      return occurrence;
    }
    get appointmentMonthDay() {
      const {
        appointment
      } = this.args;
      if (appointment.freq === 'YEARLY') {
        return (0, _moment.default)(appointment.start).format('[no dia] D [de] MMMM');
      } else {
        return (0, _moment.default)(appointment.start).format('[no dia] D');
      }
    }
    initializeValues() {
      const {
        appointment
      } = this.args;
      let frequency = this.frequencies.find(o => o.value === appointment.freq);
      if (frequency) {
        this.frequencySelected = frequency;
      } else {
        this.onChangeFrequency(this.frequencies[0]);
      }
      let weekday = appointment.byweekday;
      // Isso aqui é feito, por que o reminder tem recorrencia e é utilizado o mesmo componente, porém o formato dos dados
      // são diferentes. To-DO: Precisa ajustar o formato dos dados para ficarem iguais no futuro
      weekday = typeof weekday === 'string' ? weekday.split(',') : weekday;
      if (weekday && this.isWeekly) {
        this.daysOfWeek.forEach(day => {
          weekday.forEach(selected => {
            if (Number(selected) === day.value) {
              Ember.set(day, 'isChecked', true);
            }
          });
        });
      }
      if (appointment.isNew) {
        appointment.final = 'until';
        this.onSelectUntilDate({
          moment: appointment.start
        });
      }
    }
    onChangeFrequency(selected) {
      const {
        appointment
      } = this.args;
      this.frequencySelected = selected;
      appointment.freq = selected.value;
      if (selected.value === 'DAILY') {
        appointment.daily = 'daily';
      } else if (selected.value === 'WEEKLY') {
        appointment.interval = 1;
      } else if (selected.value === 'MONTHLY') {
        appointment.monthly = 'bymonthday';
        appointment.bymonthday = (0, _moment.default)(appointment.start).date(), appointment.interval = 1;
      } else {
        appointment.bymonth = Ember.A([parseInt((0, _moment.default)(appointment.start).format('M'))]);
        appointment.bymonthday = (0, _moment.default)(appointment.start).date();
        appointment.yearly = 'bymonthday';
      }
    }
    onChangeFinal(value) {
      const {
        appointment
      } = this.args;
      appointment.final = value;
      if (value === 'count') {
        appointment.count = 1;
        appointment.until = null;
      } else {
        appointment.count = null;
        appointment.until = (0, _moment.default)();
      }
    }
    onChangeDaily(selected) {
      const {
        appointment
      } = this.args;
      appointment.daily = selected;
      appointment.bymonth = null;
      appointment.bymonthday = null;
      appointment.bysetpos = null;
      if (selected === 'daily') {
        appointment.interval = null;
        appointment.byweekday = null;
      } else if (selected === 'weekday') {
        appointment.interval = null;
        appointment.byweekday = [0, 1, 2, 3, 4];
      } else {
        appointment.byweekday = null;
      }
    }
    onChangeMonthly(value) {
      const {
        appointment
      } = this.args;
      appointment.monthly = value;
      appointment.bymonth = null;
      appointment.bymonthday = null;
      appointment.bysetpos = null;
      appointment.byweekday = null;
      if (value === 'bymonthday') {
        appointment.bymonthday = (0, _moment.default)(appointment.start).date();
      }
    }
    onChangeYearly(value) {
      const {
        appointment
      } = this.args;
      appointment.yearly = value;
      appointment.bymonth = null;
      appointment.bymonthday = null;
      appointment.bysetpos = null;
      appointment.byweekday = null;
      appointment.interval = null;
      if (value === 'bymonthday') {
        appointment.bymonth = Ember.A([parseInt((0, _moment.default)(appointment.start).format('M'))]);
      }
    }
    onCheckDay(day) {
      const {
        appointment
      } = this.args;
      Ember.set(day, 'isChecked', !day.isChecked);
      const days = this.daysOfWeek.filterBy('isChecked').mapBy('value');
      appointment.byweekday = days;
    }
    setMonthDay(bymonthday, month) {
      const {
        appointment
      } = this.args;
      let bymonth = month ? Ember.A([month]) : null;
      appointment.bymonth = bymonth;
      appointment.bymonthday = bymonthday;
    }
    setPosition(pos, weekday, month) {
      const {
        appointment
      } = this.args;
      const byweekday = weekday ? Ember.A([weekday]) : null;
      const bymonth = month ? Ember.A([month]) : null;
      const bysetpos = pos || -1;
      appointment.bymonth = bymonth;
      appointment.bysetpos = bysetpos;
      appointment.byweekday = byweekday;
    }
    onSelectUntilDate(_ref) {
      let {
        moment: dateSelected
      } = _ref;
      const {
        appointment
      } = this.args;
      const until = (0, _moment.default)(dateSelected).endOf('day');
      appointment.until = until;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "frequencySelected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bymonths", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: 'janeiro',
        value: 1
      }, {
        label: 'fevereiro',
        value: 2
      }, {
        label: 'março',
        value: 3
      }, {
        label: 'abril',
        value: 4
      }, {
        label: 'maio',
        value: 5
      }, {
        label: 'junho',
        value: 6
      }, {
        label: 'julho',
        value: 7
      }, {
        label: 'agosto',
        value: 8
      }, {
        label: 'setembro',
        value: 9
      }, {
        label: 'outubro',
        value: 10
      }, {
        label: 'novembro',
        value: 11
      }, {
        label: 'dezembro',
        value: 12
      }]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "byweekdays", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: 'domingo',
        value: 6
      }, {
        label: 'segunda-feira',
        value: 0
      }, {
        label: 'terça-feira',
        value: 1
      }, {
        label: 'quarta-feira',
        value: 2
      }, {
        label: 'quinta-feira',
        value: 3
      }, {
        label: 'sexta-feira',
        value: 4
      }, {
        label: 'sábado',
        value: 5
      }]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "daysOfWeek", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        isChecked: false,
        label: 'Seg',
        value: 0
      }, {
        isChecked: false,
        label: 'Ter',
        value: 1
      }, {
        isChecked: false,
        label: 'Qua',
        value: 2
      }, {
        isChecked: false,
        label: 'Qui',
        value: 3
      }, {
        isChecked: false,
        label: 'Sex',
        value: 4
      }, {
        isChecked: false,
        label: 'Sab',
        value: 5
      }, {
        isChecked: false,
        label: 'Dom',
        value: 6
      }]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isAnnually", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isDaily", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isDailyInterval", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isMonthly", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isUntilFinal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isWeekly", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "appointmentWeekday", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "appointmentWeekday"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "appointmentMonthDay", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "appointmentMonthDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initializeValues", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "initializeValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFrequency", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFrequency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFinal", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFinal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeDaily", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeDaily"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeMonthly", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeMonthly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeYearly", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeYearly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCheckDay", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onCheckDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectUntilDate", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectUntilDate"), _class.prototype)), _class));
  _exports.default = RecurrentFormComponent;
});