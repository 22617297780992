define("agenda/transforms/timestamp", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TimestampTransform extends _transform.default {
    deserialize(serialized) {
      if (!serialized) {
        return null;
      }
      return _moment.default.unix(serialized).toDate();
    }
    serialize(deserialized) {
      if (!deserialized) {
        return null;
      }
      return (0, _moment.default)(deserialized).unix();
    }
  }
  _exports.default = TimestampTransform;
});