define("agenda/pods/authenticated/personal-planning/index/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedPersonalPlanningIndexEditRoute extends Ember.Route {
    async model(params) {
      return this.store.findRecord('appointment', params.appointment_id);
    }
  }
  _exports.default = AuthenticatedPersonalPlanningIndexEditRoute;
});