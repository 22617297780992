define("agenda/pods/authenticated/car-reservation/index/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NLhi2mo7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"authenticated/car-reservation/index/show/info-show\",[],[[\"@transitionTo\",\"@reservation\"],[\"index\",[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/car-reservation/index/show/template.hbs"
    }
  });
  _exports.default = _default;
});