define("agenda/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sfi6J4Cb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-login\",[],[[\"@icon\",\"@appName\"],[\"agenda\",\"Agenda\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "agenda/pods/login/template.hbs"
    }
  });
  _exports.default = _default;
});