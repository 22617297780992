define("agenda/router", ["exports", "agenda/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('404', {
      path: '/*not-found'
    });
    this.route('error');
    this.route('forbidden');
    this.route('login');
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('my-calendar', {
        path: '/'
      }, function () {
        this.route('create');
        this.route('edit', {
          path: 'my-calendar/:appointment_id/edit'
        });
        this.route('edit-recurrent', {
          path: 'my-calendar/:recurrent_appointment_id/edit-recurrent'
        });
        this.route('show-appointment', {
          path: 'my-calendar/:appointment_id/show-appointment'
        });
      });
      this.route('calendars', function () {
        this.route('show-appointment', {
          path: '/:appointment_id/show-appointment'
        });
        this.route('edit', {
          path: '/:appointment_id/edit'
        });
        this.route('edit-recurrent', {
          path: '/:recurrent_appointment_id/edit-recurrent'
        });
      });
      this.route('personal-planning', function () {
        this.route('history');
        this.route('index', {
          path: 'quadrants'
        }, function () {
          this.route('create');
          this.route('edit', {
            path: ':appointment_id/edit'
          });
        });
      });
      this.route('room-scheduling', function () {
        this.route('create');
        this.route('edit', {
          path: 'reserve/:appointment_id/edit'
        });
        this.route('show', {
          path: 'reserve/:room_reserve_id/show'
        });
      });
      this.route('reminder', function () {
        this.route('create');
        this.route('edit', {
          path: '/:reminder_id/edit'
        });
        this.route('show', {
          path: '/:reminder_id'
        });
      });
      this.route('car-reservation', function () {
        this.route('index', {
          path: 'calendar'
        }, function () {
          this.route('create');
          this.route('show', {
            path: ':car_reservation_id/show'
          });
        });
        this.route('requests', function () {
          this.route('admin', function () {
            this.route('show', {
              path: ':car_reservation_id/show'
            });
          });
          this.route('index', {
            path: 'personal'
          }, function () {
            this.route('show', {
              path: ':car_reservation_id/show'
            });
          });
          this.route('manager', function () {
            this.route('show', {
              path: ':car_reservation_id/show'
            });
          });
        });
      });
      this.route('reports', function () {
        this.route('car-reservation');
      });
      this.route('invite', function () {
        this.route('confirm', {
          path: 'confirm/:appointment_id'
        });
      });
      this.route('integrations', function () {
        this.route('google');
        this.route('outlook');
      });
    });
    this.route('privacy-policy');
  });
});