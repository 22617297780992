define("agenda/pods/authenticated/calendars/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ItemComponent extends _component.default {
    get isSelected() {
      let {
        selectedUsers,
        user
      } = this.args;
      return selectedUsers.findBy('id', user.id);
    }
    get selectedClass() {
      return this.isSelected ? 'border-left-3 border-left-primary text-primary' : '';
    }
  }
  _exports.default = ItemComponent;
});