define("agenda/pods/authenticated/car-reservation/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedCarReservationIndexRoute extends Ember.Route {
    // Além dos carros, para visualizarmos corretamente essa página precisamos carregar as car-reservations
    // Isso é gerenciado por um did-insert no calendário
    model() {
      return this.store.findAll('car');
    }
    afterModel(cars) {
      return cars.map(c => {
        // Precisa ser select pois um simple assignment faria o ember se perder nas tracked properties
        c.set('selected', true);
        return c;
      });
    }
  }
  _exports.default = AuthenticatedCarReservationIndexRoute;
});