define("agenda/pods/authenticated/room-scheduling/room-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dPROWBI6",
    "block": "{\"symbols\":[\"@room\",\"@selectRoom\"],\"statements\":[[11,\"li\"],[16,0,[31,[\"list-group-item cursor-pointer d-flex align-items-center \",[32,0,[\"selectedClass\"]]]]],[24,\"role\",\"button\"],[16,\"title\",[32,1,[\"description\"]]],[4,[38,1],[\"click\",[30,[36,0],[[32,2],[32,1]],null]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"getElement\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"mb-0 text-truncate font-weight-bold\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-insert\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/room-scheduling/room-item/template.hbs"
    }
  });
  _exports.default = _default;
});