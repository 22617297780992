define("agenda/pods/authenticated/room-scheduling/controller", ["exports", "ember-concurrency-decorators", "@fullcalendar/core", "@fullcalendar/daygrid", "@fullcalendar/interaction", "@fullcalendar/core/locales/pt-br"], function (_exports, _emberConcurrencyDecorators, _core, _daygrid, _interaction, _ptBr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedRoomSchedulingController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class AuthenticatedRoomSchedulingController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "router", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "termFilter", _descriptor8, this);
      _initializerDefineProperty(this, "selectedRooms", _descriptor9, this);
      _initializerDefineProperty(this, "rooms", _descriptor10, this);
    }
    get showClearButton() {
      return this.selectedRooms.length;
    }
    *renderCalendar(element) {
      const {
        accessProfile: {
          allowReserveMeetingRoom
        },
        admin
      } = this.permission;
      const canCreateRoomSchedule = allowReserveMeetingRoom || admin;
      const options = {
        locale: _ptBr.default,
        defaultView: 'dayGridWeek',
        plugins: [_daygrid.default, _interaction.default],
        selectable: true,
        height: 'parent',
        eventLimit: true,
        eventLimitText: 'salas',
        header: {
          left: 'prev,next createButton',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        dateClick: info => {
          this.transitionToRoute('authenticated.room-scheduling.create', {
            queryParams: {
              date: info.dateStr
            }
          });
        },
        events: (info, successCallback, failureCallback) => {
          return this.loadReserves.perform(info, successCallback, failureCallback);
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit'
        },
        eventClick: info => {
          this.transitionToRoute('authenticated.room-scheduling.show', info.event.id);
        }
      };
      if (canCreateRoomSchedule) {
        options.customButtons = {
          createButton: {
            text: 'Nova reserva',
            click: () => {
              this.router.transitionTo('authenticated.room-scheduling.create');
            }
          }
        };
      }
      try {
        let calendar = this.calendar;
        yield this.loadRooms.perform();
        try {
          calendar = new _core.Calendar(element, options);
          calendar.render();
          this.calendar = calendar;
        } catch (e) {
          Ember.debug(`Authenticated :: RoomScheduling :: controller :: renderCalendar :: ${e}`);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *loadReserves(info, successCallback, failureCallback) {
      let data = {
        start: info.startStr,
        end: info.endStr
      };
      try {
        if (this.selectedRooms.length) {
          data.rooms = this.selectedRooms.mapBy('id');
        }
        const reserves = yield this.fetch.request('/roomReserves/schedulesPerDay', {
          data
        });
        return successCallback(reserves);
      } catch (e) {
        Ember.debug(`Authenticated :: RoomScheduling :: controller :: loadReserves :: ${e}`);
        return failureCallback(e);
      }
    }
    *loadRooms() {
      try {
        let rooms = yield this.fetch.request('rooms/ajax');
        this.rooms = rooms;
        rooms = rooms.map(room => {
          room.selected = true;
          return room;
        });
        this.selectedRooms.addObjects(rooms);
      } catch (e) {
        Ember.debug(`Authenticated :: RoomScheduling :: controller :: loadRooms :: ${e}`);
      }
    }
    clearFilters() {
      this.filters = {};
      this.selectedRooms.clear();
      this.calendar.refetchEvents();
    }
    selectRoom(room) {
      let selected = this.selectedRooms.findBy('id', room.id);
      if (selected) {
        this.selectedRooms.removeObject(room);
      } else {
        this.selectedRooms.addObject(room);
      }
      this.calendar.refetchEvents();
    }
    refetchEvents() {
      this.calendar.refetchEvents();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "termFilter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedRooms", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "rooms", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "renderCalendar", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "renderCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadReserves", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadReserves"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadRooms", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadRooms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectRoom", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectRoom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refetchEvents", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "refetchEvents"), _class.prototype)), _class));
  _exports.default = AuthenticatedRoomSchedulingController;
});