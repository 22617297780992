define("agenda/pods/authenticated/calendars/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency", "@fullcalendar/core", "@fullcalendar/daygrid", "@fullcalendar/interaction", "@fullcalendar/core/locales/pt-br", "moment"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _core, _daygrid, _interaction, _ptBr, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedCalendarsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('selectedUsers.[]', 'termFilter'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class AuthenticatedCalendarsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['userId']);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "termFilter", _descriptor6, this);
      _initializerDefineProperty(this, "selectedUsers", _descriptor7, this);
    }
    get showClearButton() {
      return this.selectedUsers.length || this.termFilter;
    }
    *buildFilters() {
      try {
        yield (0, _emberConcurrency.timeout)(300);
        this.filters = {
          term: this.termFilter
        };
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *renderCalendar(element) {
      try {
        let calendar = this.calendar;
        try {
          let user = this.store.peekRecord('user', this.session.user.id);
          if (!user) {
            user = yield this.store.findRecord('user', this.session.user.id);
            this.calendar.refetchEvents();
          }
          calendar = new _core.Calendar(element, {
            eventRender: function (event) {
              event.el.title = event.el.innerText ?? '';
            },
            locale: _ptBr.default,
            plugins: [_daygrid.default, _interaction.default],
            selectable: true,
            height: 'parent',
            eventLimit: true,
            eventLimitText: 'agendas',
            customButtons: {
              createButton: {
                text: 'Criar compromisso',
                click: () => {
                  this.transitionToRoute('authenticated.my-calendar.create');
                }
              }
            },
            header: {
              left: 'prev,next createButton',
              center: 'title',
              right: 'dayGridMonth,dayGridWeek,dayGridDay'
            },
            dateClick: info => {
              this.transitionToRoute('authenticated.my-calendar.create', {
                queryParams: {
                  date: info.dateStr
                }
              });
            },
            eventTimeFormat: {
              hour: '2-digit',
              minute: '2-digit'
            },
            events: (info, successCallback, failureCallback) => {
              return this.loadAppointments.perform(info, successCallback, failureCallback);
            },
            eventClick: info => {
              this.transitionToRoute('authenticated.calendars.show-appointment', info.event.id);
            }
          });
          calendar.render();
          this.calendar = calendar;
        } catch (e) {
          Ember.debug(`Schedule controller :: renderCalendar :: ${e}`);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *loadAppointments(info, successCallback, failureCallback) {
      let data = {
        start: info.startStr,
        end: info.endStr
      };
      let appointments = Ember.A([]);
      let googleAppointments = Ember.A([]);
      try {
        if (this.selectedUsers.length) {
          console.log('this.selectedUsers', this.selectedUsers);
          const response = yield Ember.RSVP.map(this.selectedUsers, user => {
            return this.fetch.request(`users/${user.id}/appointments`, {
              data
            });
          });
          response.forEach(user => {
            appointments.pushObjects(user.appointments);
          });
          const googleAuthUsers = this.selectedUsers.filter(user => user.googleOAuth);
          const responseGoogle = yield Ember.RSVP.map(googleAuthUsers, user => {
            data.user = user.id;
            return this.fetch.request('/appointments/google', {
              data
            });
          });
          responseGoogle.forEach(response => {
            googleAppointments.pushObjects(response);
          });
        }
        appointments = appointments.uniqBy('id');
        googleAppointments = googleAppointments.uniqBy('googleCalendarId');
        appointments.map(appointment => {
          if (appointment.user !== this.session.user.id && appointment.createdBy !== this.session.user.id) {
            appointment.editable = false;
          }
          const now = (0, _moment.default)();
          const end = (0, _moment.default)(appointment.end);
          if (end.isBefore(now) && !appointment.terminated) {
            appointment.terminated = true;
          }
          if (appointment.canceled) {
            appointment.color = '#e86a69';
          } else if (appointment.terminated) {
            appointment.color = '#343a40';
          } else {
            appointment.color = '#008B71';
          }
        });
        return successCallback(appointments.concat(googleAppointments));
      } catch (e) {
        Ember.debug(`Authenticated :: Calendars :: controller :: loadAppointments :: ${e}`);
        return failureCallback(e);
      }
    }
    clearFilters() {
      this.termFilter = null;
      this.filters = {};
      this.selectedUsers.clear();
      this.calendar.refetchEvents();
    }
    refetchEvents() {
      this.calendar.refetchEvents();
    }
    selectUser(user) {
      if (user.id) {
        let selected = this.selectedUsers.findBy('id', user.id);
        if (selected) {
          this.selectedUsers.removeObject(user);
        } else {
          this.selectedUsers.addObject(user);
        }
      }
      this.calendar.refetchEvents();
    }
    addUserLed() {
      if (this.model) {
        this.selectedUsers.addObject(this.model);
      }
      this.calendar.refetchEvents();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "termFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedUsers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showClearButton", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showClearButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderCalendar", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "renderCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAppointments", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAppointments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refetchEvents", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "refetchEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectUser", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addUserLed", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addUserLed"), _class.prototype)), _class));
  _exports.default = AuthenticatedCalendarsController;
});