define("agenda/adapters/car-reservation", ["exports", "agenda/adapters/application", "agenda/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CarReservationAdapter extends _application.default {
    // O atributo end vem com o valor do predictedEnd da api, temos que alterar
    // esse comportamento no back ou tomar cuidado ao utilizar essa url em outro
    // lugar que não seja a route "authenticated.car-reservation.index"
    urlForQuery() {
      return `${_environment.default.apiUrl.agenda}/carReservations/calendar`;
    }
  }
  _exports.default = CarReservationAdapter;
});