define("agenda/pods/authenticated/room-scheduling/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "toast", _descriptor6, this);
      _initializerDefineProperty(this, "disableRoomSelect", _descriptor7, this);
      _initializerDefineProperty(this, "rooms", _descriptor8, this);
    }
    *save() {
      const {
        appointment
      } = this.args;
      const message = appointment.isNew ? 'Reserva realizada' : 'Reserva editada';
      try {
        if (appointment.validations.isInvalid) {
          return this.swal.validation(appointment.validations);
        }
        const room = yield appointment.room;
        if (!room) {
          return this.swal.warning('Informe uma sala');
        }
        const startDate = (0, _moment.default)(appointment.start, 'DD/MM/YYYY HH:mm').add(1, 'minutes').toISOString();
        const endDate = (0, _moment.default)(appointment.end, 'DD/MM/YYYY HH:mm').toISOString();
        const appointmentRoomId = appointment.room.get('id');
        if (endDate < startDate) {
          return this.swal.warning('Data e horário de início não pode ser maior ou igual a data e horário de término.');
        }
        let roomsAvailable = yield this.store.loadRecords('room', '/roomReserves/roomsAvailable', {
          data: {
            startDate,
            endDate,
            appointment: appointment.id
          }
        });
        roomsAvailable = roomsAvailable.map(room => room.id);
        if (appointmentRoomId && !roomsAvailable.includes(appointmentRoomId)) {
          return this.swal.warning('A sala selecionada não está disponível, por favor, selecione outra sala.');
        }
        if (!appointment.description) {
          appointment.description = appointment.title;
        }
        yield appointment.save();
        this.toast.success(message);
        this.router.transitionTo('authenticated.room-scheduling');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *findRoomsAvailable() {
      const {
        appointment
      } = this.args;
      const startDate = (0, _moment.default)(appointment.start, 'DD/MM/YYYY HH:mm').toISOString();
      const endDate = (0, _moment.default)(appointment.end, 'DD/MM/YYYY HH:mm').toISOString();
      if (appointment.start && appointment.end) {
        this.disableRoomSelect = false;
      }
      try {
        let roomsAvailable = yield this.store.loadRecords('room', '/roomReserves/roomsAvailable', {
          data: {
            startDate: startDate,
            endDate: endDate,
            appointment: appointment.id
          }
        });
        this.rooms = roomsAvailable;
        const roomAppointmentId = appointment.room.get('id');
        roomsAvailable = roomsAvailable.map(room => Number(room.id));
        if (roomAppointmentId && !roomsAvailable.includes(roomAppointmentId) && this.permission.user.id !== appointment.user.get('id')) {
          appointment.room = null;
          this.swal.warning('A sala selecionada não está disponível, por favor, selecione outra sala.');
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    afterSetDate() {
      this.disableRoomSelect = false;
      this.findRoomsAvailable.perform();
    }
    onChangeStartDate(_ref) {
      let {
        moment: date
      } = _ref;
      const {
        appointment
      } = this.args;
      const start = (0, _moment.default)(date).isSame(appointment.start);
      const end = appointment.end;
      if (!date || !start) {
        appointment.start = (0, _moment.default)(date).toDate();
        if ((0, _moment.default)(end).isBefore(date)) {
          appointment.end = (0, _moment.default)(date).toDate();
        }
        this.afterSetDate();
      }
    }
    onChangeEndDate(_ref2) {
      let {
        moment: date
      } = _ref2;
      const {
        appointment
      } = this.args;
      const end = (0, _moment.default)(date).isSame(appointment.end);
      const start = appointment.start;
      if (!date || !end) {
        appointment.end = (0, _moment.default)(date).toDate();
        if ((0, _moment.default)(date).isBefore(start)) {
          appointment.end = (0, _moment.default)(start).toDate();
        }
        this.afterSetDate();
      }
    }
    closeModal() {
      this.args.appointment.rollbackAttributes();
      this.router.transitionTo('authenticated.room-scheduling');
    }
    moreDetails() {
      const {
        appointment
      } = this.args;
      if (appointment.isNew) {
        this.router.transitionTo('authenticated.my-calendar.create', {
          queryParams: {
            redirectTo: 'authenticated.room-scheduling',
            title: appointment.title,
            start: appointment.start,
            end: appointment.end,
            description: appointment.description,
            room: appointment.room.get('id')
          }
        });
      } else {
        this.router.transitionTo('authenticated.my-calendar.edit', this.args.appointment, {
          queryParams: {
            redirectTo: 'authenticated.room-scheduling',
            title: appointment.title,
            start: appointment.start,
            end: appointment.end,
            description: appointment.description,
            room: appointment.room.get('id')
          }
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "disableRoomSelect", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rooms", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findRoomsAvailable", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findRoomsAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStartDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeEndDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moreDetails", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "moreDetails"), _class.prototype)), _class));
  _exports.default = FormComponent;
});