define("agenda/components/ui-card", ["exports", "ui-nela/components/ui-card"], function (_exports, _uiCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiCard.default;
    }
  });
});