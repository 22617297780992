define("agenda/components/nela-input-mask", ["exports", "nela-mask/components/nela-input-mask"], function (_exports, _nelaInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaInputMask.default;
    }
  });
});