define("agenda/pods/components/check-integrations-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZM8UA4fP",
    "block": "{\"symbols\":[\"Card\",\"@showComponent\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"   \"],[8,\"ui-card\",[[24,0,\"shadow-sm my-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Body\"]],[[24,0,\"d-flex align-items-center justify-content-between\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,0,\"mr-3\"],[15,\"src\",[32,0,[\"logoClient\"]]],[14,\"width\",\"40\"],[14,\"height\",\"40\"],[12],[13],[2,\"\\n\\n        \"],[10,\"h6\"],[12],[2,\"\\n          Você não está conectado com a agenda do \"],[1,[30,[36,0],[[32,0,[\"isGoogleClient\"]],\"Google\",\"Outlook\"],null]],[2,\", deseja conectar agora?\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"float-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-success\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"connect\"]]],null]],null],[12],[2,\"Conectar\"],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"ignoreIntegration\"]]],null]],null],[12],[2,\"Agora não\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"perform\",\"on\"]}",
    "meta": {
      "moduleName": "agenda/pods/components/check-integrations-card/template.hbs"
    }
  });
  _exports.default = _default;
});