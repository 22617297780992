define("agenda/pods/authenticated/room-scheduling/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1iCt5EOL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"authenticated/room-scheduling/form\",[],[[\"@appointment\"],[[32,0,[\"model\",\"appointment\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/room-scheduling/create/template.hbs"
    }
  });
  _exports.default = _default;
});