define("agenda/pods/authenticated/car-reservation/requests/admin/show/route", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AuthenticatedCarReservationRequestsAdminShowRoute = (_class = class AuthenticatedCarReservationRequestsAdminShowRoute extends Ember.Route {
    model(_ref) {
      let {
        car_reservation_id
      } = _ref;
      return Ember.RSVP.hash({
        reservation: this.store.loadRecord('car-reservation', `/carReservations/admin/${car_reservation_id}`),
        cars: []
      });
    }
    afterModel(model) {
      return this.loadCarsForSwap.perform(model);
    }
    loadCarsForSwap(_ref2) {
      var _this = this;
      let {
        reservation,
        cars
      } = _ref2;
      return function* () {
        const start = (0, _moment.default)(reservation.start).unix();
        const predictedEnd = (0, _moment.default)(reservation.predictedEnd).unix();
        try {
          const availableForSwap = yield _this.store.loadRecords('car', 'cars/availableForSwap', {
            data: {
              start,
              predictedEnd
            }
          });
          Ember.set(cars, 'availableForSwap', availableForSwap);
        } catch (e) {
          Ember.debug(`Authenticated :: CarReservation :: Requests :: Admin :: Show :: model :: afterModelTask :: ${e}`);
        }
      }();
    }
    deactivate() {
      this.controllerFor('authenticated.car-reservation.requests.admin').clearFilters();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "loadCarsForSwap", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadCarsForSwap"), _class.prototype)), _class);
  _exports.default = AuthenticatedCarReservationRequestsAdminShowRoute;
});