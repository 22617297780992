define("agenda/helpers/hide-if", ["exports", "ui-nela/helpers/hide-if"], function (_exports, _hideIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hideIf.default;
    }
  });
  Object.defineProperty(_exports, "hideIf", {
    enumerable: true,
    get: function () {
      return _hideIf.hideIf;
    }
  });
});