define("agenda/pods/authenticated/calendars/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dh9/lUBW",
    "block": "{\"symbols\":[\"Avatar\",\"@user\",\"@selectUser\"],\"statements\":[[11,\"li\"],[16,0,[31,[\"list-group-item cursor-pointer d-flex align-items-center \",[32,0,[\"selectedClass\"]]]]],[24,\"role\",\"button\"],[16,\"title\",[32,2,[\"fullName\"]]],[4,[38,1],[\"click\",[30,[36,0],[[32,3],[32,2]],null]],null],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0 mr-3\"]],[[\"@user\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,2,[\"fullName\"]]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex-fill text-truncate\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 text-truncate font-weight-bold\"],[12],[1,[32,2,[\"fullName\"]]],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"small text-truncate mb-0\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,2,[\"department\",\"name\"]],[32,2,[\"department\",\"name\"]],\"Não informado\"],null]],[2,\" - \\n      \"],[1,[30,[36,2],[[32,2,[\"jobRole\",\"title\"]],[32,2,[\"jobRole\",\"title\"]],\"Não informado\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/calendars/item/template.hbs"
    }
  });
  _exports.default = _default;
});