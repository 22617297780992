define("agenda/pods/authenticated/calendars/edit-recurrent/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedCalendarsEditRecurrentRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('authenticated.calendars').refetchEvents();
    }
  }
  _exports.default = AuthenticatedCalendarsEditRecurrentRoute;
});