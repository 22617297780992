define("agenda/pods/authenticated/car-reservation/index/controller", ["exports", "ember-concurrency-decorators", "@fullcalendar/core", "@fullcalendar/daygrid", "@fullcalendar/interaction", "@fullcalendar/core/locales/pt-br"], function (_exports, _emberConcurrencyDecorators, _core, _daygrid, _interaction, _ptBr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AuthenticatedCarReservationIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.filterBy('model', 'selected', true), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class AuthenticatedCarReservationIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _defineProperty(this, "allCars", true);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "selectedCars", _descriptor5, this);
    }
    *renderCalendar(element) {
      const {
        accessProfile: {
          allowReserveVehicles
        },
        admin
      } = this.permission;
      const showReserveVehicleButton = allowReserveVehicles || admin;
      let calendar = this.calendar;
      const options = {
        locale: _ptBr.default,
        plugins: [_daygrid.default, _interaction.default],
        selectable: true,
        height: 'parent',
        eventLimit: true,
        eventLimitText: 'agendas',
        header: {
          left: 'createButton',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay prev,next'
        },
        dateClick: info => {
          this.router.transitionTo('authenticated.car-reservation.index.create', {
            queryParams: {
              date: info.dateStr
            }
          });
        },
        events: (info, successCallback, failureCallback) => {
          return this.loadCarReservations.perform(info, successCallback, failureCallback);
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit'
        },
        eventClick: info => {
          this.router.transitionTo('authenticated.car-reservation.index.show', info.event.id);
        }
      };
      if (showReserveVehicleButton) {
        options.customButtons = {
          createButton: {
            text: 'Reservar carro',
            click: () => {
              this.router.transitionTo('authenticated.car-reservation.index.create');
            }
          }
        };
      }
      try {
        calendar = new _core.Calendar(element, options);
        yield calendar.render();
        this.calendar = calendar;
      } catch (e) {
        Ember.debug(`Authenticated :: CarReservation :: Index :: Controller :: renderCalendar :: ${e}`);
        return this.swal.catch(e);
      }
    }
    *loadCarReservations(info, successCallback, failureCallback) {
      try {
        const carIds = this.selectedCars.length ? this.selectedCars.map(c => Number(c.id)) : [];
        const data = {
          start: info.startStr,
          end: info.endStr,
          car: carIds
        };
        let carReservations = Ember.A([]);
        if (carIds.length) {
          carReservations = yield this.store.query('car-reservation', data);
          carReservations = carReservations.map(cr => {
            return {
              id: cr.id,
              title: cr.title,
              start: cr.start,
              end: cr.end || cr.predictedEnd,
              color: '#008B71'
            };
          });
        }
        return successCallback(carReservations);
      } catch (e) {
        Ember.debug(`Authenticated :: CarReservation :: Index :: Controller :: loadCarReservations :: ${e}`);
        return failureCallback(e);
      }
    }
    changeSelected(car) {
      car.set('selected', !car.selected);
      Ember.set(this, 'allCars', false);
      this.calendar.refetchEvents();
    }
    selectAllCars(allCars) {
      const checkedEvent = !allCars.target.checked;
      let selectAllChecked = this.model.every(car => car);
      if (checkedEvent) {
        this.model.forEach(car => car.set('selected', selectAllChecked));
      } else {
        this.model.forEach(car => car.set('selected', false));
      }
      this.calendar.refetchEvents();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedCars", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderCalendar", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "renderCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCarReservations", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadCarReservations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAllCars", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectAllCars"), _class.prototype)), _class));
  _exports.default = AuthenticatedCarReservationIndexController;
});