define("agenda/components/nela-select-basic", ["exports", "nela-select/components/nela-select-basic"], function (_exports, _nelaSelectBasic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaSelectBasic.default;
    }
  });
});