define("agenda/components/ui-slider", ["exports", "ui-nela/components/ui-slider"], function (_exports, _uiSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiSlider.default;
    }
  });
});