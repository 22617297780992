define("agenda/pods/components/alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CWCHBY3w",
    "block": "{\"symbols\":[\"alert\",\"@appointment\"],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \\t\"],[10,\"label\"],[12],[2,\"Alertas\"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"alerts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n          \"],[8,\"alerts/item\",[],[[\"@alert\"],[[32,1]]],null],[2,\"\\n        \"],[13],[2,\"     \\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,3,\"button\"],[24,0,\"btn btn-sm\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"addAlert\"]]],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-plus text-primary\"],[12],[13],[2,\" Adicionar alerta\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "agenda/pods/components/alerts/template.hbs"
    }
  });
  _exports.default = _default;
});