define("agenda/components/ui-sidebar/menu-item/submenu", ["exports", "ui-nela/components/ui-sidebar/menu-item/submenu"], function (_exports, _submenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _submenu.default;
    }
  });
});