define("agenda/pods/404/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NotFoundRoute extends Ember.Route {
    beforeModel() {
      this.transitionTo('error');
    }
  }
  _exports.default = NotFoundRoute;
});