define("agenda/pods/authenticated/reminder/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AuthenticatedReminderShowRoute extends Ember.Route {
    deactivate() {
      this.controllerFor('authenticated.reminder').clearFilter();
    }
  }
  _exports.default = AuthenticatedReminderShowRoute;
});