define("agenda/pods/authenticated/reminder/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0/0eWJ/r",
    "block": "{\"symbols\":[\"@reminder\"],\"statements\":[[10,\"li\"],[14,0,\"list-group-item d-flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-fill text-truncate\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\\n    \"],[10,\"small\"],[12],[1,[30,[36,1],[[32,1,[\"datetime\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"hasFinished\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"badge float-right badge-success\"],[12],[2,\"Finalizado\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"isRecurrent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-redo text-primary float-right\"],[14,\"title\",\"Lembrete recorrente\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"authenticated.reminder.show\",[32,1,[\"id\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "agenda/pods/authenticated/reminder/item/template.hbs"
    }
  });
  _exports.default = _default;
});