define("agenda/serializers/car-reservation", ["exports", "agenda/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CarReservationSerializer extends _application.default {
    // TODO: Desideloadear aqui mesmo? Não deveríamos alterar na api o formato dos dados?
    normalizeQueryResponse(store, primaryModelClass, payload) {
      payload['car-reservations'].forEach(cr => {
        store.pushPayload({
          'car': cr.car
        });
        cr.car = cr.car.id;
      });
      return super.normalizeQueryResponse(...arguments);
    }
  }
  _exports.default = CarReservationSerializer;
});