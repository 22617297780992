define("agenda/pods/authenticated/invite/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "agenda/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed.equal('args.invite.status', 'pending'), _dec7 = Ember.computed.equal('args.invite.status', 'rejected'), _dec8 = Ember.computed('args.invite'), (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "showAllInvitedUsers", _descriptor5, this);
      _initializerDefineProperty(this, "isPending", _descriptor6, this);
      _initializerDefineProperty(this, "isRefused", _descriptor7, this);
    }
    get urlSocial() {
      return _environment.default.appUrl.social;
    }
    get appointment() {
      const {
        invite
      } = this.args;
      let appointment = invite.recurrent ? invite.recurrentAppointment : invite.appointment;
      return appointment;
    }
    *acceptInvite() {
      const {
        invite,
        reloadFilters
      } = this.args;
      const end = this.appointment.get('end');
      const now = (0, _moment.default)();
      try {
        if (this.appointment.get('terminated') || end < now && !invite.recurrent) {
          const {
            value
          } = yield this.swal.confirm('Este evento já terminou. Gostaria de remover o convite?');
          if (!value) {
            return;
          }
          yield this.fetch.post(`appointments/${this.appointment.get('id')}/terminate`);
          reloadFilters();
          this.toast.success('Convite removido!');
        } else {
          const {
            value
          } = yield this.swal.confirm('Deseja mesmo aceitar este convite?');
          if (!value) {
            return;
          }
          let url = invite.recurrent ? `recurrentAppointments/${this.appointment.get('id')}/accept` : `appointments/${this.appointment.get('id')}/accept`;
          yield this.fetch.post(url);
          reloadFilters();
          return this.toast.success('Compromisso adicionado à agenda!');
        }
      } catch (e) {
        console.log('eee', e);
        return this.swal.catch(e);
      }
    }
    *refuseInvite() {
      const {
        invite,
        reloadFilters
      } = this.args;
      try {
        const {
          value
        } = yield this.swal.confirm('Deseja mesmo recusar este convite?');
        if (!value) {
          return;
        }
        const url = invite.recurrent ? `recurrentAppointments/${this.appointment.get('id')}/reject` : `appointments/${this.appointment.get('id')}/reject`;
        yield this.fetch.post(url);
        reloadFilters();
        this.toast.success('O compromisso foi recusado!');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showAllInvitedUsers", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isRefused", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "appointment", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "appointment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "acceptInvite", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "acceptInvite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refuseInvite", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "refuseInvite"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});