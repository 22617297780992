define("agenda/pods/components/alerts/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ItemComponent = (_dec = Ember.computed('args.alert.frequency'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.computed('alerts', 'args.type'), _dec5 = Ember.computed('args.frequencyUnit', 'units'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "alerts", Ember.A([{
        value: 'email',
        label: 'E-mail'
      }, {
        value: 'nela',
        label: 'Voors'
      }]));
      _initializerDefineProperty(this, "typeSelected", _descriptor, this);
      _initializerDefineProperty(this, "frequencyUnitSelected", _descriptor2, this);
    }
    get units() {
      const {
        alert
      } = this.args;
      return Ember.A([{
        value: 'minutes',
        label: alert.frequency > 1 ? 'minutos' : 'minuto'
      }, {
        value: 'hours',
        label: alert.frequency > 1 ? 'horas' : 'hora'
      }, {
        value: 'days',
        label: alert.frequency > 1 ? 'dias' : 'dia'
      }]);
    }
    get alertLabel() {
      const {
        alert
      } = this.args;
      let {
        label
      } = this.alerts.findBy('value', alert.type) || {};
      return label;
    }
    get frequencyLabel() {
      const {
        alert
      } = this.args;
      let {
        label
      } = this.units.findBy('value', alert.frequencyUnit) || {};
      return label;
    }
    removeAlert() {
      const {
        alert
      } = this.args;
      alert.destroyRecord();
    }
    onChangeType(selected) {
      const {
        alert
      } = this.args;
      this.typeSelected = selected;
      alert.type = selected.value;
    }
    onChangeFrequencyUnit(selected) {
      const {
        alert
      } = this.args;
      this.frequencyUnitSelected = selected;
      alert.frequencyUnit = selected.value;
    }
    initValues() {
      const {
        alert
      } = this.args;
      if (alert.type) {
        this.typeSelected = this.alerts.findBy('value', alert.type) || {};
      }
      if (alert.frequencyUnit) {
        this.frequencyUnitSelected = this.units.findBy('value', alert.frequencyUnit) || {};
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "units", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "units"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "typeSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "frequencyUnitSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "alertLabel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "alertLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "frequencyLabel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "frequencyLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAlert", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeAlert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFrequencyUnit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFrequencyUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initValues", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initValues"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});