define("agenda/components/nela-dataloader", ["exports", "nela-dataloader/components/nela-dataloader"], function (_exports, _nelaDataloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaDataloader.default;
    }
  });
});