define("agenda/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncateText = truncateText;
  function stripHTML(str) {
    str = str.replace(/&nbsp;/g, ' ');
    return str.replace(/<.*?>/g, '');
  }
  function truncateText(params, hash) {
    var value = params[0] || '';
    var len = hash.limit;
    var out = '';
    if (value !== undefined) {
      value = stripHTML(value);
      out = value.substr(0, len);
      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
    }
    return out;
  }
  var _default = Ember.Helper.helper(truncateText);
  _exports.default = _default;
});